import { createRouter, createWebHistory } from "vue-router";

import TalentProfile from "../views/TalentProfile.vue";

const routes = [
    {
        path: "/:locale/assessments/:id",
        name: "AssessmentsDetail",
        component: () => import("../views/AssessmentsDetail.vue"),
        meta: {
            title: "AssessmentsDetail",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/assessments/edit/:id",
        name: "EditAssessment",
        component: () => import("../views/EditAssessment.vue"),
        meta: {
            title: "EditAssessment",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/assessments/add",
        name: "AddAssessments",
        component: () => import("../views/AddAssessment.vue"),
        meta: {
            title: "AddAssessments",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/assessments",
        name: "AssessmentDashboard",
        component: () => import("../views/AssessmentDashboard.vue"),
        meta: {
            title: "AssessmentDashboard",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/anti-cheat/:id",
        name: "anticheat",
        component: () => import("../views/CheatingCard.vue"),
        meta: {
            title: "anti-cheat",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/profile",
        name: "MarketPlaceProfile",
        component: () => import("../views/MarketPlaceProfile.vue"),
        meta: {
            title: "MarketPlaceProfile",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/privacy-policy",
        name: "privacy policy",
        component: () => import("../views/PrivacyPolicy.vue"),
        meta: {
            title: "privacy policy",
        },
    },
    {
        path: "/invite",
        name: "InviteC",
        component: () => import("../views/Invite.vue"),
        meta: {
            title: "Invite",
        },
    },
    {
        path: "/:locale/admin",
        name: "admin",
        component: () => import("../views/AdminDashboard.vue"),
        meta: {
            title: "admin",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/Job-Positions",
        name: "Job Positions",
        component: () => import("../views/JobPositionsAdmin.vue"),
        meta: {
            title: "Job Positions",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/request-service",
        name: "request service",
        component: () => import("../views/RequestServiceView.vue"),
        meta: {
            title: "request service",
            requiresAdmin: false,
        },
    },

    {
        path: "/:locale/adminBlogs",
        name: "adminBlogs",
        component: () => import("../views/AdminBlogs.vue"),
        meta: {
            title: "adminBlogs",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/companies",
        name: "companies",
        component: () => import("../views/CompaniesBoard.vue"),
        meta: {
            title: "Manage companies",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/addBlogs",
        name: "addBlogs",
        component: () => import("../views/AddBlog.vue"),
        meta: {
            title: "addBlogs",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/updateBlog/:id",
        name: "updateBlog",
        component: () => import("../views/UpdateBlog.vue"),
        meta: {
            title: "updateBlog",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/categories",
        name: "categories",
        component: () => import("../views/AdminCategories.vue"),
        meta: {
            title: "adminCategories",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/addCategory",
        name: "addCategory",
        component: () => import("../views/AddCategory.vue"),
        meta: {
            title: "addCategory",
            requiresAdmin: true,
        },
    },
    {
        path: "/:locale/updateCategory/:id",
        name: "updateCategory",
        component: () => import("../views/UpdateCategory.vue"),
        meta: {
            title: "updateCategory",
            requiresAdmin: true,
        },
    },

    {
        path: "/:locale/blogs",
        name: "BlogsPage",
        component: () => import("../views/BlogsView.vue"),
        meta: {
            title: "blogs",
        },
    },
    {
        path: "/:locale/job-position-preview",
        name: "JobPosPreview",
        component: () => import("../views/JobPosPreview.vue"),
        meta: {
            title: "preview job position",
        },
    },
    {
        path: "/:locale/blog/:id",
        name: "DetailBlog",
        component: () => import("../views/DetailBlog.vue"),
        meta: {
            title: "blog",
        },
    },

    {
        path: "/:locale/talent/:id",
        name: "Talent",
        component: TalentProfile,
        meta: {
            title: "Talent profile",
        },
    },
    {
        path: "/:locale/",
        name: "LandingPage",
        component: () => import("../views/LandingPage.vue"),
        meta: {
            title: "Home",
            requiresAuth: false,
        },
    },
    // {
    //     path: "/", // Default route without locale
    //     redirect: () => {
    //         // Dynamically redirect to the default locale
    //         const defaultLocale = "en"; // Set your default locale here
    //         return `/${defaultLocale}/`;
    //     },
    // },
    {
        path: "/:locale/ContactUs",
        name: "ContactUs",
        component: () => import("../views/ContactUsView.vue"),
        meta: {
            title: "Contact us",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/assessments-library/:cat",
        name: "assessments-library",
        component: () => import("../views/AssessmentLibrary.vue"),
        meta: {
            title: "Assessment Tests",
            requiresAuth: false,
        },
    },

    {
        path: "/:locale/SciencePage",
        name: "SciencePage",
        component: () => import("../views/SciencePage.vue"),
        meta: {
            title: "Science Page",
            requiresAuth: false,
        },
    },

    {
        path: "/:locale/DashVed",
        name: "DashVed",
        component: () => import("../views/DashVed.vue"),
        meta: {
            title: "Dashboard ved",
        },
    },

    {
        path: "/:locale/Terms-of-use",
        name: "TermsPage",
        component: () => import("../views/TermsPage.vue"),
        meta: {
            title: "Term of use Page",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/tests/programming/testName",
        name: "TestName",
        component: () => import("../views/TestPage.vue"),
        meta: {
            title: "Assessment Tests",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/Talents",
        name: "NewLandingPage",
        component: () => import("../views/NewLandingPage.vue"),
        meta: {
            title: "Landing",
        },
    },
    {
        path: "/:locale/planning",
        name: "PlanningPage",
        component: () => import("../views/PlanningView.vue"),
        meta: {
            title: "Planing",
            requiresAuth: false,
        },
    },

    {
        path: "/:locale/contact",
        name: "contactUs",
        component: () => import("../views/contactUs.vue"),
        meta: {
            title: "Get in touch",
        },
    },
    {
        path: "/:locale/register",
        name: "RegisterPage",
        component: () => import("../views/RegisterPage.vue"),
        meta: {
            title: "Create Account",
            requiresAuth: false,
        },
    },

    {
        path: "/:locale/refferal",
        name: "RefferalView",
        component: () => import("../views/RefferalView.vue"),
        meta: {
            title: "Thank you!",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/New_Pass",
        name: "NewPassword",
        component: () => import("../views/NewPassword.vue"),
        meta: {
            title: "New password",
        },
    },

    // {
    //     path: "/:locale/library-partial",
    //     name: "library-partial",
    //     component: () => import("../views/PartialLibraryView.vue"),
    //     meta: {
    //         title: "Assessements Library",
    //         requiresAuth: true,
    //         checkCredit: true,
    //     },
    // },
    {
        path: "/:locale/library",
        name: "library",
        component: () => import("../views/LibraryView.vue"),
        meta: {
            title: "Assessements Library",
            requiresAuth: true,
            // requiresAuth: true,
        },
    },
    {
        path: "/:locale/market",
        name: "market",
        component: () => import("../views/MarketPlace.vue"),
        meta: {
            title: "market",
            //requiresAuth: true,
            // requiresAuth: true,
        },
    },
    // {
    //     path: "/:locale/security",
    //     name: "security",
    //     component: SecurityView,
    //     meta: {
    //         title: "Security",
    //         requiresAuth: true,
    //     },
    // },

    {
        path: "/:locale/payement",
        name: "payement",
        component: () => import("../views/PayementView2.vue"),
        meta: {
            title: "Payement",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/buttons",
        name: "buttons",
        component: () => import("../views/TestButtons.vue"),
        meta: {
            title: "buttons",
            requiresAuth: false,
        },
    },

    {
        path: "/:locale/dashboard",
        name: "dashboard",
        component: () => import("../views/PartialLibraryView.vue"),
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/boards",
        name: "boards",
        component: () => import("../views/DashboardView"),
        meta: {
            title: "Boards",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/board/:id",
        name: "board",
        component: () => import("../views/DashboardView"),
        meta: {
            title: "Board",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/dummy-dashboard",
        name: "dummy board",
        component: () => import("../views/dummyDashboard/DummyDashboard"),
        meta: {
            title: "Dummy Board",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/dummy-anti-cheat/:id",
        name: "dummy-anti-cheat",
        component: () => import("../views/dummyDashboard/DummyCheatingCard.vue"),
        meta: {
            title: "anti-cheat",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/personalitytest",
        name: "PersonalityTest",
        component: () => import("../views/PersonalityTest_copy.vue"),
        meta: {
            title: "Profile",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/linkedin-success",
        name: "LinkedInSuccess",
        component: () => import("../components/LinkedInSuccess.vue"),
    },
    {
        path: "/:locale/payementForm",
        name: "PayementForm",
        component: () => import("../views/PayementForm.vue"),
        meta: {
            title: "Payement",
            requiresAuth: true,
        },
    },
    // {
    //     path: "/:locale/CardPerformance",
    //     name: "CardPerformance",
    //     component: CardPerformance,
    //     meta: {
    //         title: "Card Performance",
    //         requiresAuth: true,
    //     },
    // },

    {
        path: "/:locale/companyprofile",
        name: "companyProfile",
        component: () => import("../views/CompanyProfile.vue"),
        meta: {
            title: "Company",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/login",
        name: "LogIn",
        component: () => import("../views/LogIn.vue"),
        meta: {
            title: "Login",
        },
    },
    // {
    //     path: "/:locale/linechart",
    //     name: "LineChart",
    //     component: LineChart,
    //     meta: {
    //         title: "New password",
    //     },
    // },
    {
        path: "/:locale/invoice",
        name: "invoice",
        component: () => import("../views/InvoiceView.vue"),
        meta: {
            title: "Invoice",
            requiresAuth: true,
        },
    },
    // {
    //     path: "/:locale/archive",
    //     name: "archive",
    //     component: ArchiveView,
    //     meta: {
    //         title: "Archive",
    //         requiresAuth: true,
    //     },
    // },
    {
        path: "/:locale/manage",
        name: "manage",
        component: () => import("../views/ManageView.vue"),
        meta: {
            title: "Manage",
            requiresAuth: true,
        },
    },

    // {
    //     path: "/:locale/preview",
    //     name: "preview",
    //     component: AssessementPreview,
    //     meta: {
    //         title: "New password",
    //     },
    // },
    // {
    //     path: "/:locale/subscriptions",
    //     name: "Subscriptions",
    //     component: subscriptionsComponent,
    //     props: true, // Enable passing props to the component
    // },
    {
        path: "/:locale/InvitedCoworker",
        name: "InvitedCoworker",
        component: () => import("../views/InvitedCoworker.vue"),
        props: true, // Enable passing props to the component
    },

    {
        path: "/:locale/DashVideo",
        name: "DashVideo",
        component: () => import("../views/DashVideo.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Dashboard Video",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/CheatTab",
        name: "CheatTab",
        component: () => import("../views/AntiCheatBoard.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Dashboard Anti-Cheat",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/InvitedCoworkerTab",
        name: "InvitedCoworkerTab",
        component: () => import("../views/InvitedCoworkerTable.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Company",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/CompareTalent",
        name: "CompareTalent",
        component: () => import("../views/compareView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Compare",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/ChangePassword",
        name: "ChangePassword",
        component: () => import("../views/ChangePassword.vue"),
        meta: {
            title: "Change password",
        },
    },
    {
        path: "/:locale/newAssessment",
        name: "NewAssessement",
        component: () => import("../views/NewAssessment.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "new assessment",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/candidate",
        name: "CandidateActivity",
        component: () => import("@/components/CandidateActivity.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "candidate",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/activity",
        name: "activity",
        component: () => import("@/views/ActivityView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "activity",
            requiresAuth: true,
        },
    },

    {
        path: "/:locale/previewAssessment",
        name: "Sample-Questions",
        component: () => import("../views/QuestionsSample.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "preview assessment",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/preview-project",
        name: "QuestionsSample",
        component: () => import("../views/PreviewProject.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "preview-project",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/settings",
        name: "profile settings",
        component: () => import("../views/SettingsView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "settings",
            requiresAuth: true,
        },
    },
    {
        path: "/:locale/Tour",
        name: "ProductTour",
        component: () => import("../views/ProductTour.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Product Tour",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/Terms",
        name: "Terms",
        component: () => import("../views/PolicyTerms.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Policy & terms of use",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/BPO-and-outsourcing",
        name: "BPO-and-outsourcing",
        component: () => import("../views/OutSourcingView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "BPO-and-outsourcing",
            requiresAuth: false,
        },
    },
    {
        path: "/:locale/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../views/NotFoundView.vue"),
        meta: {
            title: "Not Found",
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
/*const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router*/
